//
//
//
//
//
//
//
//
//
//
//

import LookForm from "../../components/lookForm";
import LookFormApproval from "../../components/lookFormApproval";
//
import applyDetail from "@/lib/data-service/haolv-default/consumer_apply_detail";

// 超标审批详情 2
import consumer_overproof_detail from "@/lib/data-service/haolv-default/consumer_overproof_detail";

// 改签审批详情 4
import change_detail from "@/lib/data-service/haolv-default/consumer_journey_getApplyChange";

// 退订审批详情 5
import refund_detail from "@/lib/data-service/haolv-default/consumer_journey_getApplyRefund";
import consumer_apply_workflowDetail from "@/lib/data-service/haolv-default/consumer_apply_workflowDetail";
import {getProcessInstanceInfo, operablePermissions} from "@/lib/data-service/haolv-default/design";
export default {
  name: "ApproveInfo",
  data() {
    return {
      loadingBox: false,
      type: "",
      detailData: {},
      approveType: '',
    };
  },
  components: { LookFormApproval,LookForm },
  methods: {
    // getDetail(approveType) {
    //   if (approveType === 2) {

    //   } else if (approveType === 3) {

    //   }
    //   let id = this.$route.query.applyId || "";
    //   applyDetail({ id }).then(res => {
    //     this.detailData = res.datas;
    //   });
    // },
    getDetail() {
      this.loadingBox = true;
      const id = this.$route.query.applyId || this.$route.query.id;
      const approveType = Number(this.$route.query.approveType);
      const applyNo = this.$route.query.applyNo || '';
      const approveStatus =  Number(this.$route.query.approveStatus || '0');
        const  listApproveStatus =  Number(this.$route.query.listApproveStatus || '0');
      // approveType 审批类型：1.出差申请，2报销申请，3.超标申请，4.改签，5.退票
        const appType = Number(this.$route.query.appType); // 1.我的申请 2待我审批
      if (approveType === 2) {
        consumer_overproof_detail({ applyId: id, applyNo, appType, approveStatus: listApproveStatus })
          .then(res => {
            const datas = res.datas;
            const evection = datas.evectionDetailResponse;
            let detail = {
              evection,
              staffDetailVos: datas.evectionDetailResponse.travellerList,
              recordList: datas.recordList,
              initialName: evection.initialName,
              overproofs: {
                applyNo: datas.applyNo,
                applyName: datas.applyName,
                feeAffiliationName: evection.feeAffiliationName,
                overproofList: datas.overproofs,
              },
            };
            
            // 审批类型 1-酒店退 2-机票退 3火车票退 4机票改 5火车票改
            this.detailData = detail;
            this.loadingBox = false;
              if (this.$store.state.workflow === '1') {
                  this.$nextTick(()=>{
                      this.$refs.lookForm.getFlow();
                  });
              }
          })
          .catch(err => {});
      } else if (approveType === 4 || approveType === 8) {
        change_detail({ id, applyNo, appType, approveStatus: listApproveStatus })
          .then(res => {
            const detail = res.datas;
            // 审批类型 1-酒店退 2-机票退 3火车票退 4机票改 5火车票改
            const type = Number(detail.type);
            if (type === 4) {

            } else if (type === 5) {

            }
            this.detailData = detail;
            this.loadingBox = false;
              if (this.$store.state.workflow === '1') {
                  this.$nextTick(()=>{
                      if (approveType === 4) {
                          console.log('22222')
                          this.$refs.lookForm.getFlow();
                      }
                      if (approveType === 8) {
                          this.$refs.LookFormApproval.getFlow();
                      }
                  });
              }
          })
          .catch(err => {});
      } else if (approveType === 5 || approveType === 9) {
        refund_detail({ id, applyNo, appType, approveStatus: listApproveStatus })
          .then(res => {
            const detail = res.datas; // 审批类型 1-酒店退 2-机票退 3火车票退 4机票改 5火车票改
            const type = Number(detail.type);
            if (type === 1) {

            } else if (type === 2) {

            } else if (type === 3) {

            }
            this.detailData = detail;
            this.loadingBox = false;
              if (this.$store.state.workflow === '1') {
                  this.$nextTick(()=>{
                      if (approveType === 5) {
                          console.log('22222')
                          this.$refs.lookForm.getFlow();
                      }
                      if (approveType === 9) {
                          this.$refs.LookFormApproval.getFlow();
                      }
                  });
              }
          })
          .catch(err => {});
      } else {
        let detailApi = this.$store.state.workflow === '1' ? consumer_apply_workflowDetail({applyNo: applyNo, appType, approveStatus: listApproveStatus}) : applyDetail({id});
        detailApi.then(res => {
          this.detailData = res.datas;
          this.loadingBox = false;

          if (this.$store.state.workflow === '1') {
            this.$nextTick(()=>{
                if (approveType === 1) {
                    console.log('22222')
                    this.$refs.lookForm.getFlow();
                }
                if (approveType === 7) {
                    this.$refs.LookFormApproval.getFlow();
                }
            });
          }
        });
      }
    },
    init () {
      const query = this.$route.query;
      const approveType = Number(query.approveType);
      this.type = query.type || "";
      this.getDetail(approveType);
    }
  },
  created() {
    
  },

  async activated() {
    this.approveType = Number(this.$route.query.approveType);
    await this.$store.state.workflowDefer.promise;
    this.init();
  },
  watch: {},
  computed: {}
};
